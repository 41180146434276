import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsSection from "../components/projectsSection"
import LastCTASection from "../components/lastCTASection"
import { BorderedHeader, Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import BorderedHeaderWrapper from "../components/borderedHeaderWrapper"
import { BigMobileFont, MediumMobileFont } from "../components/typography"
import styled from "styled-components"
import ArrowMore from "../components/arrowMore"
import BaseImg from "../components/baseImg"
import SidesBorderedBox from "../components/sidesBorderedBox"

const TitleProjectsListBox = styled.div`
  margin-right: 90px;
  @media (max-width: 1195px) {
    margin-right: 40px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 2rem;
  }
`

const Jumbotron = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 850px;
  padding: 46px 64px;
  text-align: center;
  margin: 120px auto;
  @media (max-width: 768px) {
    margin: 60px auto;
    padding: 28px;
  }
`

const ColumnImage = styled(BaseImg)`
  width: 47%;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 80%;
  }
`
const HeroSection = styled.div`
  display: flex;
  min-height: calc(100vh - 180px);
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SecondaryColor = styled.span`
  color: ${(props) => props.theme.secondary};
`

const ProjectsList = styled.ul`
  padding: 0 0 0 2rem;
  margin: 0;
  border-left: 1px solid ${(props) => props.theme.secondary};
  list-style: none;

  .project {
    font-weight: 600;
    cursor: pointer;
    background: none;
    border: none;

    :focus {
      outline: none;
    }
  }

  .project.active {
    text-decoration: line-through ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
  }
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const PortfolioImg = styled(BaseImg)`
  position: absolute;
  right: -7.5vw;
  z-index: -1;
  width: 50vw;
  @media (max-width: 778px) {
    right: -20vw;
    width: 75vw;
    top: -25px;
  }
  @media (max-width: 450px) {
    right: -25vw;
    width: 100%;
    top: 40px;
  }
`

const SinglePortfolio = ({ pageContext }) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          metabox_global_settings_templates {
            portfolioBusinessNeedsTitle
            portfolioBusinessTitle
            portfolioChallengesTitle
            portfolioDescriptionTitle
            portfolioGoalTitle
            portfolioServicesTitle
            portfolioSimilarTitle
            portfolioSolutionsTitle
            portfolioTechnologiesTitle
            portfolioTitle
            portfolioVisitTitle
          }
        }
      }
    }
  `)
  const {
    portfolioBusinessNeedsTitle,
    portfolioBusinessTitle,
    portfolioChallengesTitle,
    portfolioDescriptionTitle,
    portfolioGoalTitle,
    portfolioServicesTitle,
    portfolioSimilarTitle,
    portfolioSolutionsTitle,
    portfolioTechnologiesTitle,
    portfolioTitle,
    portfolioVisitTitle,
  } = wordpressPage.cmb2.metabox_global_settings_templates
  const { metaboxes, projects, yoast_meta, pagePath } = pageContext

  const {
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
  } = metaboxes
  const activeProject = projects.find((project) => project.path === pagePath)
  const {
    business,
    businessNeedsContent,
    challengesContent,
    businessNeedsImage,
    description,
    challengesImage,
    heroImage,
    mainGoalText,
    projectDescriptionContent,
    services,
    solutionsContent,
    projectDescriptionImage,
    solutionsImage,
    visit_url,
    technologies,
  } = activeProject.cmb2.metabox_post_project
  return (
    <Layout>
      <SEO
        meta={yoast_meta}
        title={`${portfolioTitle} eCommerce`}
        path={pagePath}
      />
      <Container>
        <HeroSection>
          <TitleProjectsListBox>
            <h1>
              <BigMobileFont desktopFontSize="2.5rem">
                {portfolioTitle}
                <br />
                <SecondaryColor>eCommerce</SecondaryColor>
              </BigMobileFont>
            </h1>
            <ProjectsList>
              {projects.map((project) => (
                <li key={project.title}>
                  <a
                    href={project.path}
                    className={
                      project.title === activeProject.title
                        ? "project active"
                        : "project"
                    }
                    key={project.title}
                  >
                    {project.title}
                  </a>
                </li>
              ))}
            </ProjectsList>
          </TitleProjectsListBox>
          <div
            style={{
              lineHeight: "1.625",
              background: "#fff",
              boxShadow: "0px 3px 6px #00000029",
              padding: "28px 36px",
              maxWidth: "478px",
              position: "relative",
            }}
          >
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont>{activeProject.title}</MediumMobileFont>
            </h2>
            <p
              dangerouslySetInnerHTML={{ __html: description }}
              style={{ marginBottom: "0.5rem" }}
            ></p>
            <p style={{ marginBottom: "0.5rem" }}>
              <span style={{ fontWeight: "700" }}>
                {portfolioServicesTitle}:
              </span>{" "}
              {services
                ? services.map((service, index) =>
                    index === services.length - 1 ? service : service + " | "
                  )
                : null}
            </p>
            <p style={{ marginBottom: "0.5rem" }}>
              <span style={{ fontWeight: "700" }}>
                {portfolioBusinessTitle}:
              </span>{" "}
              {business
                ? business.map((singleBusiness, index) =>
                    index === business.length - 1
                      ? singleBusiness
                      : singleBusiness + " | "
                  )
                : null}
            </p>
            <p style={{ marginBottom: "0" }}>
              <span style={{ fontWeight: "700" }}>{portfolioVisitTitle}: </span>
              <a href={visit_url} target="_blank" rel="noopener noreferrer">
                {visit_url}
              </a>
            </p>

            <div style={{ position: "absolute", bottom: "-78px", left: "0" }}>
              <ArrowMore />
            </div>
          </div>

          <PortfolioImg
            alt=""
            localFile={heroImage ? heroImage.localFile : ""}
          />
        </HeroSection>
        <FlexContainer>
          <ColumnImage localFile={businessNeedsImage.localFile} alt="" />
          <SidesBorderedBox side="right">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont>{portfolioBusinessNeedsTitle}</MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: businessNeedsContent }}
            ></div>
          </SidesBorderedBox>
        </FlexContainer>
        <Jumbotron>
          <h2 style={{ marginBottom: "0.75rem" }}>
            <SecondaryColor>
              <MediumMobileFont>{portfolioGoalTitle}</MediumMobileFont>
            </SecondaryColor>
          </h2>
          <p
            dangerouslySetInnerHTML={{ __html: mainGoalText }}
            style={{ marginBottom: "0" }}
          ></p>
        </Jumbotron>
        <FlexContainer>
          <SidesBorderedBox side="left">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont>{portfolioChallengesTitle}</MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: challengesContent }}
            ></div>
          </SidesBorderedBox>
          <ColumnImage localFile={challengesImage.localFile} alt="" />
        </FlexContainer>
        <BaseImg
          style={{
            width: "800px",
            display: "block",
            margin: "120px auto -50px",
            zIndex: "-1",
            position: "relative",
          }}
          localFile={projectDescriptionImage.localFile}
          alt=""
        />
        <Jumbotron>
          <h2 style={{ marginBottom: "0.75rem" }}>
            <MediumMobileFont>{portfolioDescriptionTitle}</MediumMobileFont>
          </h2>
          <div
            style={{ fontFamily: "Hind", lineHeight: "1.625" }}
            dangerouslySetInnerHTML={{ __html: projectDescriptionContent }}
          ></div>
        </Jumbotron>
        <FlexContainer>
          <ColumnImage localFile={solutionsImage.localFile} alt="" />
          <SidesBorderedBox side="right">
            <h2 style={{ marginBottom: "0.75rem" }}>
              <MediumMobileFont>{portfolioSolutionsTitle}</MediumMobileFont>
            </h2>
            <div
              style={{ fontFamily: "Hind", lineHeight: "1.625" }}
              dangerouslySetInnerHTML={{ __html: solutionsContent }}
            ></div>
          </SidesBorderedBox>
        </FlexContainer>
        <div style={{ margin: "120px auto" }}>
          <CenterBorderedHeader size="small">
            <BigMobileFont desktopFontSize="2.5rem">
              {portfolioTechnologiesTitle}
            </BigMobileFont>
          </CenterBorderedHeader>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {technologies.map((technology, index) => (
              <BaseImg
                key={index}
                style={{ width: "250px", padding: "2rem 1rem", margin: "0" }}
                localFile={technology.image.localFile}
                alt=""
              />
            ))}
          </div>
        </div>
        <FlexColumn>
          {/* Needed for Projects Section mobile order to work */}
          <BorderedHeaderWrapper side="right">
            <BorderedHeader whiteSpace="nowrap" side="right">
              <BigMobileFont desktopFontSize="2.5rem">
                {portfolioSimilarTitle}
              </BigMobileFont>
            </BorderedHeader>
          </BorderedHeaderWrapper>
          <ProjectsSection />
        </FlexColumn>
        <LastCTASection
          title={lastCTASectionTitle}
          text={lastCTASectionText}
          buttonText={lastCTASectionButtonText}
          buttonUrl={lastCTASectionButtonUrl}
        />
      </Container>
    </Layout>
  )
}

export default SinglePortfolio
